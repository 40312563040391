import { DialogContent } from "@/components/ui/dialog";
import { MissingAssets } from "@/api/generated/data-contracts";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/modals/useModal";
import { useTranslation } from "react-i18next";
import { useMutationOrderMissingDevices } from "@/lib/hooks/query/order/useMutateOrderMissingDevices";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useQueryOrderById } from "@/lib/hooks/query/order/useQueryOrderById";
import { AlertOctagon } from "lucide-react";
import { useEffect } from "react";

export interface InboundMissingDevicesModalProps {
  orderId: number;
  missingDevices: MissingAssets;
}

export function InspectMissingDevicesModal({
  orderId,
  missingDevices,
}: Readonly<InboundMissingDevicesModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inspect.modal.missing-devices",
  });
  const { hideModal } = useModal();

  const orderQuery = useQueryOrderById(orderId);

  const mutationMissingDevices = useMutationOrderMissingDevices();

  const handleMarkMissing = async () => {
    await mutationMissingDevices.mutateAsync({ orderId });
    hideModal();
  };

  const handleKeepWaiting = () => {
    hideModal();
  };

  useEffect(() => {
    const missingAssets = missingDevices.assetsMissing ?? [];
    if (missingAssets.length <= 0) {
      hideModal();
    }
  }, [hideModal, missingDevices.assetsMissing]);

  const totalDevicesCount = missingDevices.orderTotalAssets ?? 0;
  const inboundDevicesCount = missingDevices.orderCheckedInAssets ?? 0;
  const missingDevicesCount = totalDevicesCount - inboundDevicesCount;

  const isPending = mutationMissingDevices.isPending;

  const shownDevices = missingDevices.assetsMissing?.slice(0, 4) ?? [];
  const extraDevices = missingDevices.assetsMissing?.slice(4) ?? [];

  return (
    <DialogContent className="flex flex-col gap-6 sm:max-w-lg">
      <AlertOctagon className="size-12 self-center" />
      <h2 className="text-lg font-semibold">
        {t("count-devices-missing-from-order", { count: missingDevicesCount })}
      </h2>
      <span className="font-semibold text-[#7C8B9D]">
        {t("all-inbound-devices-have-been-inspected-for-order", { orderId })}
      </span>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <span className="text-sm font-medium text-[#344054]">
            {t("order-number")}
          </span>
          <span className="text-[#667085]">{orderId}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium text-[#344054]">
            {t("end-customer")}
          </span>
          <span className="text-[#667085]">
            {orderQuery.data?.endCustomerName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium text-[#344054]">
            {t("partner")}
          </span>
          <span className="text-[#667085]">
            {orderQuery.data?.partnerName ?? "-"}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="text-sm font-medium text-[#344054]">
            {t("missing-devices")}
          </span>
          <ul className="list-inside list-disc	">
            {shownDevices.map((asset) => (
              <li key={asset}>{asset}</li>
            ))}
          </ul>
          {extraDevices.length > 0 && (
            <li className="text-[#667085]">
              {t("extra-devices", { count: extraDevices.length })}
            </li>
          )}
        </div>
      </div>

      <div className="flex w-full flex-col gap-2">
        <Button onClick={handleMarkMissing} disabled={isPending}>
          {isPending && <LoadingSpinner className="mr-3 size-5" />}
          {t("mark-devices-as-missing")}
        </Button>
        <Button
          variant="secondary"
          onClick={handleKeepWaiting}
          disabled={isPending}
        >
          {t("keep-devices-as-waiting")}
        </Button>
      </div>
    </DialogContent>
  );
}
