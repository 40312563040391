import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { Tag } from "@/api/generated/data-contracts";

interface MutationVariables {
  endCustomerId: number;
  tag: string;
}

export function useMutateEndCustomerTag(
  options?: Omit<
    UseMutationOptions<Tag, HttpResponse<void, Error>, MutationVariables>,
    "mutationFn"
  >,
) {
  return useMutation<Tag, HttpResponse<void, Error>, MutationVariables>({
    ...options,
    mutationFn: async ({ endCustomerId, tag }) => {
      const response = await apiClient.endCustomersTagCreate(endCustomerId, {
        value: tag,
      });
      return response.data;
    },
  });
}
