import { DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/modals/useModal";
import { useTranslation } from "react-i18next";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ClipboardPasteIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useStateIdTranslation } from "@/lib/hooks/useStateIdTranslation";

export interface InboundMissingDevicesModalProps {
  targetState: number;
  previousStorageName?: string;
  onSubmit?: (storageName: string) => Promise<void>;
}

export function InspectSetStorageNameModal({
  targetState,
  onSubmit,
  previousStorageName,
}: Readonly<InboundMissingDevicesModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "inspect.modal.set-storage-unit",
  });
  const tState = useStateIdTranslation();

  const { hideModal } = useModal();

  const [storageName, setStorageName] = useState("");

  const handleCancel = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const handleSubmit = useCallback(async () => {
    await onSubmit?.(storageName);
    hideModal();
  }, [hideModal, onSubmit, storageName]);

  const handlePrevious = useCallback(async () => {
    if (!previousStorageName)
      throw new Error("Previous storage name is required");
    setStorageName(previousStorageName);
  }, [previousStorageName]);

  const isValid = storageName.length > 0;

  return (
    <DialogContent className="flex flex-col items-center gap-6 sm:max-w-md">
      <h2 className="self-start text-lg font-semibold">
        {t("scan-storage-unit-for", { state: tState(targetState) })}
      </h2>

      <Label className="flex flex-col gap-1 self-stretch">
        {t("storage-unit-code")}
        <Input
          placeholder={t("storage-unit-code")}
          onChange={(e) => setStorageName(e.target.value)}
          value={storageName}
        />
      </Label>

      <span className="text-sm font-medium opacity-30">
        {t("scan-storage-unit-barcode-or-add-it-manually")}
      </span>
      <Button
        variant="secondary"
        className="w-min"
        onClick={handlePrevious}
        disabled={!previousStorageName}
      >
        <ClipboardPasteIcon className="mr-2" />
        {t("use-previous-device-storage-code")}
      </Button>

      <div className="grid w-full grid-cols-2 gap-2">
        <Button variant="secondary" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button disabled={!isValid} onClick={handleSubmit}>
          {t("ok")}
        </Button>
      </div>
    </DialogContent>
  );
}
