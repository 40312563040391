import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { useModal } from "@/lib/modals/useModal";
import { DialogDescription } from "@radix-ui/react-dialog";

export interface ErrorModalProps {
  title: string;
  subtitle?: string;
  onApprove: () => void;
}

export function ConfirmModal({
  title,
  subtitle,
  onApprove,
}: Readonly<ErrorModalProps>) {
  const { t } = useTranslation("", {
    keyPrefix: "common.modal.confirm",
  });
  const { hideModal } = useModal();

  const handleApprove = () => {
    onApprove?.();
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader className="flex flex-col items-center">
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <DialogDescription className="flex h-4 flex-col items-center">
        {subtitle}
      </DialogDescription>
      <DialogFooter>
        <Button variant="secondary" className="w-full" onClick={hideModal}>
          {t("cancel")}
        </Button>
        <Button className="w-full" onClick={handleApprove}>
          {t("confirm")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
