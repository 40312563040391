import { Asset } from "@/api/generated/data-contracts";
import { HttpResponse } from "@/api/generated/http-client";
import { useInboundLabelPrinter } from "@/lib/hooks/printing/labels/useInboundLabelPrinter";
import { useMutationCheckInDevice } from "@/lib/hooks/query/device/useMutationCheckInDevice";
import { useMutationGetDeviceBySerialNumber } from "@/lib/hooks/query/device/useMutationGetDeviceBySerialNumber";
import { useMutationEndCustomerById } from "@/lib/hooks/query/endcustomer/useMutateEndCustomerById";
import { useMutationOrderById } from "@/lib/hooks/query/order/useMutateOrderById";
import { useModal } from "@/lib/modals/useModal";
import {
  scannedDevicesAtom,
  targetOrderIdAtom,
  isAutoPrintAtom,
  ScannedDevice,
} from "@/views/inbound/inboundState";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export function useScannedDevices() {
  const { t } = useTranslation("", { keyPrefix: "inbound.toast" });
  const { showModal } = useModal();

  const [scannedDevices, setScannedDevices] = useAtom(scannedDevicesAtom);
  const [targetOrderId, setTargetOrderId] = useAtom(targetOrderIdAtom);
  const isAutoPrint = useAtomValue(isAutoPrintAtom);

  const getBySerialMutation = useMutationGetDeviceBySerialNumber();
  const checkInMutation = useMutationCheckInDevice();
  const orderMutation = useMutationOrderById();
  const endCustomerMutation = useMutationEndCustomerById();

  const { printLabel } = useInboundLabelPrinter();

  const isFirstDeviceSet = useMemo(
    () => targetOrderId !== undefined,
    [targetOrderId],
  );

  const updateDevice = useCallback(
    (targetDevice: ScannedDevice, updatedFields: Partial<ScannedDevice>) => {
      setScannedDevices((oldDevices) => {
        return oldDevices.map((device) => {
          if (device !== targetDevice) return device;
          return Object.assign(targetDevice, updatedFields);
        });
      });
    },
    [setScannedDevices],
  );

  const handlePrintLabel = useCallback(
    async (device: ScannedDevice) => {
      if (device.orderId === undefined) throw new Error("Order ID is missing");
      const order = await orderMutation.mutateAsync({
        orderId: device.orderId!,
      });
      const endCustomer = await endCustomerMutation.mutateAsync({
        id: order.endCustomerId,
      });
      await printLabel([
        {
          orderId: device.orderId!,
          serialNumber: device.serialNumber!,
          eolTrackingNumber: device.eolTrackingNumber!,
          tag: endCustomer.tag!,
        },
      ]);
      updateDevice(device, {
        isPrinted: true,
      });
    },
    [endCustomerMutation, orderMutation, printLabel, updateDevice],
  );

  const checkInDevice = useCallback(
    async (targetDevice: ScannedDevice) => {
      try {
        if (
          scannedDevices.some(
            (d) => d.id !== undefined && d.id === targetDevice.id,
          )
        ) {
          toast.error(t("this-device-has-already-been-scanned"));
          setScannedDevices((oldDevices) =>
            oldDevices.filter((d) => d !== targetDevice),
          );
          return;
        }

        const checkedInDevice = await checkInMutation.mutateAsync({
          device: targetDevice,
        });

        if (targetOrderId === undefined) {
          setTargetOrderId(checkedInDevice.orderId);
        }

        updateDevice(targetDevice, {
          id: checkedInDevice.id,
          state: "success",
          eolTrackingNumber:
            checkedInDevice.eolTrackingNumber ?? targetDevice.eolTrackingNumber,
          checkInTime: new Date(),
          manufacturer:
            checkedInDevice.manufacturer ?? targetDevice.manufacturer,
          model: checkedInDevice.productName ?? targetDevice.model,
          orderId: checkedInDevice.orderId ?? targetDevice.orderId,
          categoryId:
            checkedInDevice.classificationCategoryId ?? targetDevice.categoryId,
          isExtra: checkedInDevice.eolBatchItemTypeId !== 1,
        });
      } catch (error) {
        if (error instanceof Response) {
          const response = error as HttpResponse<
            Asset,
            { code?: number; message?: string }
          >;
          const message = response.error.message ?? t("an-error-occurred");
          showModal("Error", { title: message });
        } else {
          showModal("Error", { title: t("an-error-occurred") });
        }
        setScannedDevices((oldDevices) => {
          const newDevices = oldDevices.filter(
            (device) => device !== targetDevice,
          );
          if (newDevices.length === 0) {
            setTargetOrderId(undefined);
          }
          return newDevices;
        });
      }
      if (isAutoPrint) await handlePrintLabel(targetDevice);
    },
    [
      checkInMutation,
      handlePrintLabel,
      isAutoPrint,
      scannedDevices,
      setScannedDevices,
      setTargetOrderId,
      showModal,
      t,
      targetOrderId,
      updateDevice,
    ],
  );

  const handleSingleFound = useCallback(
    async (targetDevice: ScannedDevice, matchedDevices: Asset[]) => {
      const device = matchedDevices[0];
      if (!isFirstDeviceSet || targetOrderId === device.orderId) {
        const order = await orderMutation.mutateAsync({
          orderId: device.orderId,
        });
        const endCustomer = await endCustomerMutation.mutateAsync({
          id: order.endCustomerId,
        });
        if (!endCustomer.tag) {
          let isCanceled = false;
          await showModal("MissingTag", {
            disableClose: true,
            endCustomer,
            onCancel: () => {
              isCanceled = true;
              setScannedDevices((oldDevices) =>
                oldDevices.filter((device) => targetDevice !== device),
              );
            },
          });
          if (isCanceled) return;
        }
        updateDevice(targetDevice, {
          id: device.id,
        });
        await checkInDevice(targetDevice);
      } else {
        updateDevice(targetDevice, {
          state: "error",
          orderId: device.orderId,
          manufacturer: device.manufacturer ?? targetDevice.manufacturer,
          model: device.productName ?? targetDevice.model,
          categoryId:
            device.classificationCategoryId ?? targetDevice.categoryId,
          eolTrackingNumber:
            device.eolTrackingNumber ?? targetDevice.eolTrackingNumber,
        });
      }
    },
    [
      checkInDevice,
      endCustomerMutation,
      isFirstDeviceSet,
      orderMutation,
      setScannedDevices,
      showModal,
      targetOrderId,
      updateDevice,
    ],
  );

  const inboundDevice = useCallback(
    async (device: Asset) => {
      const newDevice: ScannedDevice = {
        id: device.id!,
        serialNumber: device.serialNumber!,
        state: "loading",
        isPrinted: false,
        isExtra: false,
        checkInTime: new Date(),
      };
      const order = await orderMutation.mutateAsync({
        orderId: device.orderId,
      });
      const endCustomer = await endCustomerMutation.mutateAsync({
        id: order.endCustomerId,
      });
      if (!endCustomer.tag) {
        let isCanceled = false;
        await showModal("MissingTag", {
          disableClose: true,
          endCustomer,
          onCancel: () => {
            isCanceled = true;
          },
        });
        if (isCanceled) return;
      }
      setScannedDevices((prev) => [...prev, newDevice]);
      await checkInDevice(newDevice);
    },
    [
      checkInDevice,
      endCustomerMutation,
      orderMutation,
      setScannedDevices,
      showModal,
    ],
  );

  const inboundExtraDevice = useCallback(
    async (serialNumber: string) => {
      const newDevice: ScannedDevice = {
        serialNumber: serialNumber,
        state: "loading",
        isPrinted: false,
        isExtra: false,
        checkInTime: new Date(),
      };
      setScannedDevices((prev) => [...prev, newDevice]);
      await showModal("InboundAddDevice", {
        disableClose: true,
        device: newDevice,
        onConfirm: (updatedDevice: ScannedDevice) => {
          updateDevice(newDevice, updatedDevice);
          checkInDevice(newDevice);
        },
        onCancel: () => {
          setScannedDevices((oldDevices) =>
            oldDevices.filter((device) => newDevice !== device),
          );
        },
      });
    },
    [checkInDevice, setScannedDevices, showModal, updateDevice],
  );

  const addSerialDevice = useCallback(
    async (serialNumber: string) => {
      const matchedDevice = await getBySerialMutation.mutateAsync({
        serialNumber,
      });
      if (matchedDevice.length === 1) {
        const newDevice: ScannedDevice = {
          serialNumber,
          state: "loading",
          isPrinted: false,
          isExtra: false,
          checkInTime: new Date(),
        };
        setScannedDevices((prev) => [...prev, newDevice]);
        await handleSingleFound(newDevice, matchedDevice);
      } else {
        await showModal("InspectDeviceSearch", { serialNumber });
      }
    },
    [getBySerialMutation, handleSingleFound, setScannedDevices, showModal],
  );

  const addManualDevice = useCallback(async () => {
    await showModal("InspectDeviceSearch", { serialNumber: "" });
  }, [showModal]);

  return {
    scannedDevices,
    updateDevice,
    addManualDevice,
    addSerialDevice,
    inboundDevice,
    inboundExtraDevice,
  };
}
