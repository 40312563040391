import { atomWithReset } from "jotai/utils";
import { InboundEditDeviceModal } from "./inbound/EditDeviceModal";
import { InboundChangePrinterModal } from "@/lib/modals/inbound/ChangePrinterModal";
import { InboundAddExtraDeviceFromOtherOrderModal } from "@/lib/modals/inbound/AddExtraDeviceFromOtherOrderModal";
import { InboundAddDeviceModal } from "@/lib/modals/inbound/AddDeviceModal";
import { InboundRemoveDeviceFromCheckInModal } from "@/lib/modals/inbound/RemoveDeviceFromCheckInModal";
import { InboundSelectCorrectDeviceModal } from "@/lib/modals/inbound/SelectCorrectDeviceModal";
import { InspectMissingDevicesModal } from "@/lib/modals/inspect/MissingDevicesModal";
import { ErrorModal } from "@/lib/modals/common/ErrorModal";
import { MissingTagModal } from "@/lib/modals/inbound/MissingTagModal";
import { ManualTrackingNumberModal } from "@/lib/modals/common/ManualTrackingNumberModal";
import { InspectManualSerialNumberModal } from "@/lib/modals/inbound/ManualSerialNumberModal";
import { InboundPrintModal } from "@/lib/modals/inbound/PrintModal";
import { CreateEndCustomerModal } from "@/lib/modals/endCustomer/CreateEndCustomerModal";
import { DeleteEndCustomerModal } from "@/lib/modals/endCustomer/DeleteEndCustomerModal";
import { CreatePartnerModal } from "@/lib/modals/partner/CreatePartnerModal";
import { ChangeDeviceStateModal } from "@/lib/modals/common/ChangeDeviceStateModal";
import { InspectDeviceSearchModal } from "@/lib/modals/inbound/DeviceSearchModal";
import { CreateProductCodeModal } from "@/lib/modals/common/CreateProductCodeModal";
import { ConfirmModal } from "@/lib/modals/common/ConfirmModal";
import { InspectSetStorageNameModal } from "@/lib/modals/inspect/InspectSetStorageNameModal";

export const MODAL_COMPONENTS = {
  InboundEditDevice: InboundEditDeviceModal,
  InboundAddDevice: InboundAddDeviceModal,
  InboundChangePrinter: InboundChangePrinterModal,
  InboundAddExtraDeviceFromOtherOrder: InboundAddExtraDeviceFromOtherOrderModal,
  InboundRemoveDeviceFromCheckIn: InboundRemoveDeviceFromCheckInModal,
  InboundSelectCorrectDevice: InboundSelectCorrectDeviceModal,
  InspectMissingDevices: InspectMissingDevicesModal,
  InspectDeviceSearch: InspectDeviceSearchModal,
  InspectSetStorageName: InspectSetStorageNameModal,
  Error: ErrorModal,
  MissingTag: MissingTagModal,
  ManualTrackingNumber: ManualTrackingNumberModal,
  InspectManualSerialNumber: InspectManualSerialNumberModal,
  InboundPrint: InboundPrintModal,
  CreateEndCustomer: CreateEndCustomerModal,
  DeleteEndCustomer: DeleteEndCustomerModal,
  CreatePartner: CreatePartnerModal,
  ChangeDeviceState: ChangeDeviceStateModal,
  CreateProductCode: CreateProductCodeModal,
  Confirm: ConfirmModal,
} as const;

export type ModalType = keyof typeof MODAL_COMPONENTS;
export type ModalProps<T extends keyof typeof MODAL_COMPONENTS> =
  BaseModalProps & Parameters<(typeof MODAL_COMPONENTS)[T]>[0];

export interface BaseModalProps {
  disableClose?: boolean;
}

export interface ModalState<T extends ModalType = ModalType> {
  modalType?: T;
  modalProps?: ModalProps<T>;
  modalResolve?: () => void;
}

export const modalStateAtom = atomWithReset<ModalState>({
  modalType: undefined,
  modalProps: undefined,
  modalResolve: undefined,
});
